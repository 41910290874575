import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Icon from '../components/Icon';
import Button from '../components/Button';
import Hero from '../components/Hero';
import colBkgVFT from '../images/img-home-vft.jpg';
import colBkgCoop from '../images/img-home-coop-careers.jpg';

const IndexPage = ({ data }) => (
  <Layout title="Home">
    <Hero />
    <Section className="home__banner">
      <Container className="banner">
        <Row>
          <Column large={8} className="banner__info">
            <Image
              filename="img-home-tractor-icon.svg"
              className="icon-tractor"
            />
            <div className="banner__copy">
              <p>Meet the team in an agricultural cooperative</p>
            </div>
          </Column>
          <Column large={4} className="banner__cta">
            <Button to="virtual-field-trip">
              Watch Now
              <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="home__image-text">
      <Container>
        <Row>
          <Column
            large={6}
            style={{ backgroundImage: `url(${colBkgVFT})` }}
            className="pt-6 pl-2 pr-2 pb-3 home__background-img-col"
          >
            <div className="home__text-wrapper">
              <h2>Virtual Field Trip</h2>
              <p className="mb-0">
                <strong>See a Real Agricultural Cooperative in Action</strong>
              </p>
              <p>
                Explore the ways agricultural cooperatives (co-ops) come together
                to achieve a unified goal through individual expertise, teamwork,
                and innovative technologies. Along the way, students will meet the
                awesome personalities working behind-the-scenes within a
                successful co-op to learn the keys to the agricultural co-op
                business model.
              </p>
              <Button to="virtual-field-trip">Watch Now!</Button>
            </div>
          </Column>
          <Column
            large={6}
            style={{ backgroundImage: `url(${colBkgCoop})` }}
            className="pt-6 pl-2 pr-2 pb-3 home__background-img-col"
          >
            {/* <div className="home__coming-soon">
              <span>COMING SOON</span>
            </div> */}
            <div className="home__text-wrapper">
              <h2>Co-op Careers</h2>
              <p className="mb-0">
                <strong>Sow the Seeds of Student Success</strong>
              </p>
              <p>
                Discover the diverse network of talented professionals that come
                together to create agricultural co-ops, from truck drivers to
                nutrition consultants and beyond. Get high school students
                thinking proactively about their future by immersing them in the
                lives of real-world pros who cultivated careers by following their
                own interests and passions.
              </p>
              <p>
                <Button to="cooperative-careers">Learn More</Button>
              </p>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="home__cooperative">
      <Container>
        <Row>
          <Column large={5}>
            <div className="element-square"></div>
            <Image
              filename="img-home-sprout-icon.png"
              className="icon-sprout"
            />
          </Column>
          <Column large={7} className="home__cooperative-copy">
            <h2>What’s a Cooperative?</h2>
            <p>
              <strong>Together We Grow</strong>
            </p>
            <p>
              Find out exactly what a <strong>cooperative</strong> is and
              uncover how the <strong>agricultural co-op</strong> business model
              is helping organizations and communities thrive across the country
              and the world.
            </p>
            <p>
              Introduce students to the{' '}
              <strong>7 Cooperative Principles</strong> that guide every
              successful cooperative venture.
            </p>
            <Button to="what-is-a-cooperative">Learn More</Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-2">
      <Container>
        <Row>
          <Column large={7} className="pt-4 pb-1">
            <h2>About the Program</h2>
            <h3>Getting Students into the Business of Cooperatives</h3>
            <p>
              Join the CHS Foundation and Discovery Education as we immerse high
              school students nationwide in the dynamic ingenuity and
              collaborative spirit of the cooperative business model. Empower
              students to cultivate success with no-cost, standards-aligned
              resources that explore essential careers and endless possibilities
              in agricultural cooperatives.
            </p>
            <Button to="about">Learn More</Button>
          </Column>
          <Column large={5} className="home__cooperative-copy">
            <Image filename="img-home-female-student.png" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
